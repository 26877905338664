import { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import MainLogo from "../../assets/image/main-logo.png";
// import MainLogo from "../../assets/image/jgc-new-logo.png";
import Clip from './images/clip.png'
import Dropdown from "react-bootstrap/Dropdown";
import backgroundImage from "../../assets/image/visitor_logo.png"
import MainLogo from "./images/isji.png";
import Select from "react-select";
import moment from "moment";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ModalBody, Spinner } from "react-bootstrap";
import "../../../src/stylecss/mainpage.css";
import Swiper from "swiper";
import "swiper/css";

import WOW from "wowjs";
import "animate.css/animate.min.css";
import { message } from "antd";
import Printable from "./Printable";
import useFetchData from "../../common/useFetchData";

import Footer from '../superAdmin/footer/Footer'
import CommonHeader from "../../common/CommonHeader";
import CommonFileUploader from "../../common/CommonFileUploader";
import PrintPage from "./PrintPage";
import CameraCapture from "./CameraCapture";
import CameraComponent from "./CameraCapture";

const NewVisitorForm = () => {
    const [show, setShow] = useState(true);
    const [number, setNumber] = useState("");
    const [visitorName, setVisitorName] = useState("");
    const [visitorPhoneNumber, setVisitorPhoneNumber] = useState("");
    const [visitorEmailId, setVisitorEmailId] = useState("");
    const [showFormVisibility, setShowFormVisibility] = useState(true);

    const [photo, setPhoto] = useState();
    const [idProofFront, setIDProofFront] = useState();
    const [idProofBack, setIDProofBack] = useState();
    const [category, setCategory] = useState("visitor");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pincode, setPincode] = useState(null)
    const [address, setAddress] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [visitingCard, setVisitingCard] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState("");
    const [comapnyList, setComapnyList] = useState(null);
    const [invitedByCompany, setInvitedByCompany] = useState(null);
    const [visitorListData, setVisitorListData] = useState("");
    const [registrationDate, setRegistrationDate] = useState("");
    const [companyshow, setCompanyShow] = useState(false);
    const [visiting_as, setVisiting_as] = useState("");
    const [agentsshow, setAgentsShow] = useState(false);
    const [gst_number, setGst_number] = useState("");
    const [showError, setShowError] = useState("");
    const handleClose = () => setShow(false);
    const [duplicateError, setDuplicateError] = useState(null);
    const [isChecked, setIsChecked] = useState(true);
    const { showData, error } = useFetchData();
    const [showSuccessPage, setShowSuccessPage] = useState(false)
    const [gst_data_present, setGst_data_present] = useState(null)
    const handleChange = (e) => {
        setIsChecked(!isChecked);
        console.log("asdasdd", isChecked);
    };
    const visitingAsArray = [
        "Buyer",
        "Retailer",
        "Wholesaler",
        "Manufacturer",
        // "Agent",
        // "Other",
    ];

    let currentLocation = useLocation().pathname;

    // useEffect(() => {
    //   if (currentLocation === "/kce-visitor-registration") {
    //     setShow(true);
    //   }
    // }, []);

    // for navigation
    // const nav = () => {
    //   navigate("/visitor-registration");
    // };

    // useEffect(() => {
    //   axios
    //     .get(`${process.env.REACT_APP_API_END_POINT}/get-registration-visibility`)
    //     .then((res) => {
    //       if (res.data.data.visitor === "active") {
    //         setShowFormVisibility(true);
    //       } else {
    //         setShowFormVisibility(false);
    //       }
    //     })
    //     .catch((err) => {
    //       // LoginRedirection("/superadmin/login");
    //       if (err.response && err.response.status === 401) {
    //         navigate("/superadmin/login");
    //         localStorage.removeItem("jff_token");
    //         localStorage.removeItem("category");
    //         localStorage.removeItem("permission");
    //         localStorage.removeItem("user_id");
    //         localStorage.removeItem("type_of_user");
    //       }
    //     });
    // }, []);

    const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    function checkRegex(input) {
        return regex.test(input);
    }

    function convertToUpperCase(input) {
        return input.replace(/[a-z]/gi, (char) => char.toUpperCase());
    }

    const handleGstNumberChange = (e) => {
        setGst_number(convertToUpperCase(e.target.value));
        const value = e.target.value;
        if (checkRegex(value)) {
            console.log("asdasdasd", e.target.value);
            setShowError("");
        } else {
            setShowError("Provide Valid GSTIN");
        }
    };

    useEffect(() => {
        console.log("hello i am here to see the test daeeed", gst_number.length);

        if (gst_number.length === 15 && checkRegex(gst_number)) {
            // setReloadShow(true);
            // ;

            axios
                .get(
                    `${process.env.REACT_APP_API_END_POINT}/check-gst-validation/${gst_number}`
                )
                .then((resData) => {
                    // setReloadShow(false);
                    // console.log(
                    //   "hello i am here to see the test daeeed",
                    //   resData.data.message,
                    //   resData.data.data.lgnm,
                    //   resData.data.data.pradr.adr
                    // );
                    // if (resData.data.message === "GSTIN  found.") {
                    console.log("gst find or not", resData.data);
                    if (resData.data.flag === false) {
                        setShowError(resData.data.message);
                        return;
                    }
                    setGst_data_present(resData.data)
                    setShowError("");
                    setCompanyName(resData.data.company);
                    setAddress(resData.data.address);
                    setCity(resData.data.city);
                    setPincode(resData.data.pincode)
                    setState(resData.data.state)

                    // }
                });
        }
    }, [gst_number]);

    function handlePhoto(e) {
        setPhoto(e.target.files[0]);
    }
    function handleIDFront(e) {
        setIDProofFront(e.target.files[0]);
    }
    function handleIDBack(e) {
        setIDProofBack(e.target.files[0]);
    }

    function handleCard(e) {
        setVisitingCard(e.target.files[0]);
    }

    function validateGstNumber(gst_number) {
        // If gst_number is not empty, then perform the checks
        if (gst_number && (gst_number.length !== 15 || !checkRegex(gst_number))) {
            return false;
        }
        // If gst_number is empty, skip the checks and return true
        return true;
    }
    const isValid = validateGstNumber(gst_number);
    const handleSubmit = () => {

        if (!visitorName) {
            message.error("Name is mandatory");
        } else if (!gst_number || !isValid) {
            message.error("Gst Number Is mandatory");

        } else if (!isValid) {
            message.error("Fill Valid Gst Number");
            setIsLoading(false);
        } else if (!number) {
            message.error("Mobile No. is mandatory");
        } else if (number.length !== 10) {
            message.error("Please Enter 10 Digit Mobile Number");
            setIsLoading(false);
            return;
        }
        else if (!companyName) {
            message.error("Company Name is mandatory");
        }
        else if (!email) {
            message.error("Email is mandatory");
            // } else if (emailError) {
            //   alert("Invalid email format");
            //   setIsLoading(false);
            //   return;
        }
        else if (!address) {
            message.error("Address is mandatory");
        } else if (!city) {
            message.error("City is mandatory");
        } else if (!state) {
            message.error("State is mandatory");
        } else if (!pincode) {
            message.error("Pincode is mandatory");
        } else if (!country) {
            message.error("Country is mandatory");
        } else if (!visiting_as) {
            message.error("Visiting As Mandatory");
            setIsLoading(false);
        } else if (!photo) {
            message.error("Photo is mandatory");
        } else if (!idProofFront) {
            message.error("ID Proof Front is mandatory");
        } else if (!idProofBack) {
            message.error("ID Proof Back is mandatory");
        } else {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("mobile_number", number);
            formData.append("visitor_name", visitorName);
            formData.append("photo", photo);
            formData.append("id_proof_front", idProofFront);
            formData.append("id_proof_back", idProofBack);
            formData.append("gst_number", gst_number);
            formData.append("visiting_as", visiting_as);
            // formData.append("invited_exhibitor_id", invitedByCompany.value);
            formData.append("category", category);
            formData.append("email", email);
            formData.append("city", city);
            formData.append("state", state);
            formData.append("country", country);

            formData.append("street_address", address);
            formData.append("visiting_card", visitingCard);
            formData.append("company_name", companyName);
            axios
                .post(
                    `${process.env.REACT_APP_API_END_POINT}/visitor-registration`,
                    formData
                )
                .then((res) => {
                    const value = res.data.message;
                    if (value === "Buyer" || value === "Visitor") {
                        message.error(`This Person Is Allready Presented As ${value}`);
                    } else {
                        setShowSuccessPage(true)
                        setVisitorEmailId(value.email);
                        localStorage.setItem('barcode', value.barcode)
                        setVisitorListData(value.barcode);
                        setVisitorPhoneNumber(value.mobile_number);
                        setRegistrationDate(moment(value.createdAt).format("MMM Do YY"));
                        setShow(false);
                        setSuccessMessage(true);
                        setIsLoading(false);
                        setNumber("");
                    }
                });
        }
    };

    const handleMobileChange = (e) => {
        const value = e.target.value;
        // Use a regular expression to check if the input is a number
        if (/^\d*$/.test(value)) {
            setNumber(value);
            setMobileError("");
        } else {
            setMobileError("Mobile must contain only numbers.");
        }
    };
    const handleContactPersonChange = (e) => {
        const newValue = e.target.value;
        const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
        setVisitorName(alphabetOnlyValue);
    };
    const handleCity = (e) => {
        const newValue = e.target.value;
        const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
        setCity(alphabetOnlyValue);
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if (!emailPattern.test(value)) {
            setEmailError("Invalid email format");
        } else {
            setEmailError("");
        }
    };
    useEffect(() => {
        // Initialize Swiper
        const mySwiper = new Swiper(".swiper-container", {
            // Swiper options and configuration here
            slidesPerView: 1,
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }, []);

    const [isActive, setIsActive] = useState(false);

    // Function to toggle the class
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    // Define the class name based on the state
    const className = isActive ? "our-story-show" : "our-story-hide";
    // Define the button text based on the state
    const buttonText = isActive ? "Know Less" : "Know More";

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    const handleMobileNoChange = (e) => {
        const input = e.target.value;
        const numericValue = input.replace(/\D/g, "");
        setNumber(numericValue);

        if (numericValue.length === 10) {
            axios
                .get(
                    `${process.env.REACT_APP_API_END_POINT}/check-is-number/${numericValue}/visitor`
                )
                .then((resData) => {
                    if (resData.data.status && resData.data.message === "vip") {
                        setDuplicateError("vip");
                    } else if (resData.data.status) {
                        setDuplicateError("true");
                    } else {
                        setDuplicateError(null);
                    }
                });
        }
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_END_POINT}/exhibitor-lists-data`)
            .then((resData) => {
                setComapnyList(resData.data.data);
            });
    }, []);
    return (
        <>
            <div>
                <div className="container-fluid">

                    <div className="row vh-100">
                        {/* Image Section - 65% width */}
                        <div className="col-lg-7 col-md-12  d-lg-block p-0 img-col" style={{ position: 'fixed' }}>
                            <img
                                src={backgroundImage}
                                alt="Placeholder"
                                className="img-fluid vh-100 w-100 top-img"
                                style={{ objectFit: 'cover' }}
                            />
                        </div>

                        {/* Form Section - 35% width */}
                        <div className="col-lg-5 col-md-12 d-flex justify-content-center align-items-center form-col" style={{ position: "absolute", right: "0px" }}>
                            {showSuccessPage ? (<>  <PrintPage
                                barcode={localStorage.getItem("barcode")}
                                text={"Your registration was successfully completed."}
                            /></>) : (<form className="w-100 px-4">
                                <div className="text-aling-center">

                                    <img src={MainLogo} alt={process.env.REACT_APP_ORGANIZER_NAME}
                                        className="logo-img"
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                            objectFit: 'contain',
                                            display: 'flex',
                                            margin: '0px auto 10px'

                                        }}
                                    />
                                    <h4 className="text-center mb-15" >Visitor Registration Form </h4>
                                </div>
                                {/* Form Inputs */}
                                <Printable />


                                {showData && showData.visitor === "active" ? (
                                    <div className="row">
                                        <div className="col-lg-6 col-12 regis-form-group">
                                            <label className="fw-bold">
                                                Mobile No. <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                type="text"
                                                value={number}
                                                placeholder="Mobile"
                                                className="form-control my-2"
                                                maxLength="10"
                                                onChange={handleMobileNoChange}
                                            // onBlur={() => {
                                            //     setDuplicateError(duplicateError === "false" ? null : duplicateError);
                                            // }}
                                            />
                                            {duplicateError === "true" && (
                                                <p className="text-danger">
                                                    This Visitor is already registered.
                                                </p>
                                            )}
                                            {duplicateError === "vip" && (
                                                <p className="text-danger">You already Registered as Buyer</p>
                                            )}
                                            {duplicateError === "false" && (
                                                <p className="text-success">This number is available.</p>
                                            )}
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group">
                                            <label className="fw-bold">Gst Number
                                                <font color="#FF0000">*</font>

                                            </label>

                                            <input
                                                type="text"
                                                placeholder="Gst Number"
                                                value={gst_number}
                                                className="form-control my-2"
                                                disabled={duplicateError === "vip" || duplicateError === "true"}
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true") ? "#cdcdcd" : "#fff"}` }}

                                                onChange={handleGstNumberChange}
                                            />
                                            {showError && <p className="text-danger">{showError}</p>}
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group">
                                            <label className="fw-bold">
                                                Name <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true") ? "#cdcdcd" : "#fff"}` }}

                                                disabled={duplicateError === "vip" || duplicateError === "true"}
                                                className="form-control my-2"
                                                onChange={(e) => {
                                                    handleContactPersonChange(e);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group mt-2">
                                            <label className="fw-bold">
                                                Your Company <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                disabled={duplicateError === "vip" || duplicateError === "true" || gst_data_present?.company}
                                                type="text"
                                                placeholder="Your Company"
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true" || gst_data_present?.company) ? "#cdcdcd" : "#fff"}` }}

                                                className="form-control my-2"
                                                value={companyName}
                                                onChange={(e) => {
                                                    setCompanyName(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group">
                                            <label className="fw-bold">
                                                Email <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                type="text"
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true") ? "#cdcdcd" : "#fff"}` }}

                                                placeholder="Email"
                                                disabled={duplicateError === "vip" || duplicateError === "true"}
                                                className="form-control my-2"
                                                onChange={(e) => handleEmailChange(e)}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group mt-2">
                                            <label className="fw-bold">
                                                Address <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                type="text"
                                                disabled={duplicateError === "vip" || duplicateError === "true" || gst_data_present?.address}
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true" || gst_data_present?.address) ? "#cdcdcd" : "#fff"}` }}

                                                placeholder="Address"
                                                className="form-control my-2"
                                                value={address}

                                                onChange={(e) => {
                                                    setAddress(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group">
                                            <label className="fw-bold">
                                                City <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="City"
                                                className="form-control my-2"
                                                disabled={duplicateError === "vip" || duplicateError === "true" || gst_data_present?.city}
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true" || gst_data_present?.city) ? "#cdcdcd" : "#fff"}` }}

                                                value={city}
                                                onChange={(e) => {
                                                    handleCity(e);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group">
                                            <label className="fw-bold">
                                                State <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="State"
                                                className="form-control my-2"
                                                disabled={duplicateError === "vip" || duplicateError === "true" || gst_data_present?.state}
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true" || gst_data_present?.state) ? "#cdcdcd" : "#fff"}` }}

                                                value={state}
                                                onChange={(e) => {
                                                    setState(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group">
                                            <label className="fw-bold">
                                                Pincode <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Pincode"
                                                className="form-control my-2"
                                                disabled={duplicateError === "vip" || duplicateError === "true" || gst_data_present?.pincode}
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true" || gst_data_present?.pincode) ? "#cdcdcd" : "#fff"}` }}

                                                value={pincode}
                                                onChange={(e) => {
                                                    setPincode(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group">
                                            <label className="fw-bold">
                                                Country <font color="#FF0000">*</font>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Country"
                                                className="form-control my-2"
                                                disabled={duplicateError === "vip" || duplicateError === "true"}
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true") ? "#cdcdcd" : "#fff"}` }}

                                                value={country}
                                                onChange={(e) => {
                                                    setCountry(e.target.value);
                                                }}
                                            />
                                        </div>


                                        {/* <div className="form-group col-lg-6 col-md-12">
                                            <label className="fw-bold mb-2">
                                                Invited Company{" "}
                                                <font color="#FF0000">*</font>
                                            </label>
                                            <Select
                                                options={
                                                    comapnyList &&
                                                    comapnyList.map((data) => ({
                                                        value: data.value,
                                                        label: data.label,
                                                    }))
                                                }
                                                disabled={duplicateError === "vip" || duplicateError === "true"}

                                                value={
                                                    invitedByCompany
                                                        ? {
                                                            value: invitedByCompany.value,
                                                            label: invitedByCompany.label,
                                                        }
                                                        : null
                                                }
                                                onChange={(selectedOption) => {
                                                    setInvitedByCompany(selectedOption ? selectedOption : null);
                                                }}
                                                isSearchable
                                                placeholder="Select Company"
                                            />
                                        </div> */}

                                        <div className="form-group col-lg-6 col-12">
                                            <label className="fw-bold mb-2">
                                                Visiting As
                                                <font color="#FF0000">*</font>
                                            </label>
                                            <Select
                                                options={
                                                    visitingAsArray &&
                                                    visitingAsArray.map((data) => ({
                                                        value: data,
                                                        label: data,
                                                    }))
                                                }
                                                disabled={duplicateError === "vip" || duplicateError === "true"}

                                                value={
                                                    visiting_as
                                                        ? {
                                                            value: visiting_as,
                                                            label: visiting_as,
                                                        }
                                                        : null
                                                }
                                                onChange={(selectedOption) => {
                                                    setVisiting_as(selectedOption ? selectedOption.value : null);
                                                }}
                                                isSearchable
                                                placeholder="Select visiting as"
                                            />

                                        </div>


                                        <div className="col-lg-6 col-12 regis-form-group">

                                            <label className="fw-bold">
                                                Upload Pic <font color="#FF0000">*</font>
                                            </label>
                                            {/* <input
                                                type="file"
                                                className="form-control my-2"
                                                accept=".jpg, .jpeg, .png"
                                                onChange={handlePhoto}
                                                disabled={duplicateError === "vip" || duplicateError === "true"}
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true") ? "#cdcdcd" : "#fff"}` }}

                                            /> */}
                                            <div className="file-input-container">
                                                {/* <CameraCapture photo={photo} setPhoto={setPhoto} /> */}
                                                {/* <h1>Camera Feed</h1> */}
                                                <CameraCapture setPhoto={setPhoto} />
                                                {/* <CommonFileUploader handlePhoto={handlePhoto} fileName={photo ? photo?.name : "Select File"}
                                                    duplicateError={duplicateError}
                                                    accept=".jpg, .jpeg, .png"
                                                /> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group">
                                            <label className="fw-bold">
                                                Upload ID Front <font color="#FF0000">*</font>
                                            </label>
                                            <CommonFileUploader handlePhoto={handleIDFront} fileName={idProofFront ? idProofFront?.name : "Select File"}
                                                duplicateError={duplicateError}
                                                accept=".jpg, .jpeg, .png"
                                            />
                                            {idProofFront && (
                                                // Otherwise, show the preview of the uploaded image
                                                <div className="image-preview">
                                                    <img
                                                        src={URL.createObjectURL(idProofFront)}
                                                        alt="Preview"
                                                        style={{ width: '100%', height: 'auto' }}
                                                    />
                                                </div>
                                            )}



                                        </div>
                                        <div className="col-lg-6 col-12 regis-form-group mb-3">
                                            <label className="fw-bold">
                                                Upload ID Back <font color="#FF0000">*</font>
                                            </label>
                                            <CommonFileUploader handlePhoto={handleIDBack} fileName={idProofBack ? idProofBack?.name : "Select File"}
                                                duplicateError={duplicateError}
                                                accept=".jpg, .jpeg, .png"
                                            />
                                            {idProofBack && (
                                                // Otherwise, show the preview of the uploaded image
                                                <div className="image-preview">
                                                    <img
                                                        src={URL.createObjectURL(idProofBack)}
                                                        alt="Preview"
                                                        style={{ width: '100%', height: 'auto' }}
                                                    />
                                                </div>
                                            )}

                                            {/* <input
                                                type="file"
                                                id="idback"
                                                className="form-control my-2"
                                                onChange={handleIDBack}
                                                disabled={duplicateError === "vip" || duplicateError === "true"}
                                                style={{ background: `${(duplicateError === "vip" || duplicateError === "true") ? "#cdcdcd" : "#fff"}` }}

                                                accept=".jpg, .jpeg, .png"
                                            /> */}
                                        </div>
                                        {/* <div className="form-check">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={!isChecked}
                                                    onChange={handleChange}
                                                    disabled={duplicateError === "vip" || duplicateError === "true"}
                                                    style={{ background: `${(duplicateError === "vip" || duplicateError === "true") ? "#cdcdcd" : "#fff"}` }}


                                                />
                                                <span className="ms-2">
                                                    I agree to pay the registration fee of ₹ 1000/-
                                                </span>
                                            </label>
                                        </div> */}
                                        <div className="col-12 form-group mb-3 mt-3">
                                            <Button
                                                type="button"
                                                className="regis w-100"
                                                style={{ backgroundColor: '#8d0035' }}
                                                onClick={handleSubmit}

                                                disabled={isLoading || duplicateError === "vip" || duplicateError === "true"}
                                            >
                                                {isLoading ? (
                                                    <Spinner animation="border" size="sm" />
                                                ) : (
                                                    "Register"
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="container text-center my-5">
                                        <div className="card shadow-lg p-3 mb-5 bg-white rounded">
                                            <div className="card-body">
                                                <h1 className="card-title text-danger">
                                                    Visitor Registration Suspended
                                                </h1>
                                                <p className="card-text text-muted">
                                                    We apologize for the inconvenience. The visitor registration
                                                    is currently suspended. Please check back later.
                                                </p>
                                                <div className="mt-4">
                                                    <a href="/" className="btn btn-success">
                                                        Go to Homepage
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Submit Button */}

                            </form>)}
                        </div>
                    </div>
                </div >
            </div >
            {/* <Footer /> */}
        </>
    )
}

export default NewVisitorForm